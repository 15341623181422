// React
import React,
	   {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook,
		faGithub,
		faInstagram,
		faLinkedin} from "@fortawesome/free-brands-svg-icons";

// App
import LOGO2 from "../assets/G-UL-Logo2.png";


class Footer extends Component
{
	render()
	{
		return (
			<footer className="hello-footer">
				<div className="container">
					<div className="row">
						<div className="col-md-4">
							<span className="footer-copyright">
								© SeungHwan, G-UL, Um
							</span>
						</div>

						<div className="col-md-8">
							<ul className="footer-icon">
								<li>
									<a className="footer-icon"
									   href="https://www.instagram.com/ii_gul_ii/"
									   rel="noopener noreferrer"
									   target="_blank">
										<FontAwesomeIcon icon={faInstagram} />
									</a>
								</li>

								<li>
									<a className="footer-icon"
									   href="https://github.com/G-UL"
									   rel="noopener noreferrer"
									   target="_blank">
										<FontAwesomeIcon icon={faGithub} />
									</a>
								</li>

								<li>
									<a className="footer-icon"
									   href="https://www.linkedin.com/in/g-ul"
									   rel="noopener noreferrer"
									   target="_blank">
										<FontAwesomeIcon icon={faLinkedin} />
									</a>
								</li>

								<li>
									<a className="footer-icon"
									   href="https://www.facebook.com/stuperfection.g.ul"
									   rel="noopener noreferrer"
									   target="_blank">
										<FontAwesomeIcon icon={faFacebook} />
									</a>
								</li>

								<li>
									<a href="https://g-ul.me"
									   rel="noopener noreferrer"
									   style={{background: "white",
											   marginRight: "38px"}}
									   target="_blank">
										<img alt={"logo"}
											 src={LOGO2} />
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</footer>
		);
	}
};


export default Footer;
