// React
import React,
	   {Component} from "react";
import {Col,
		Container,
		Row} from "react-bootstrap";

// App
import Me from "../assets/Me_with_Drum.JPG";


class AboutMe extends Component
{
	render()
	{
		return (
			<section id="AboutMe">
				<Container>
					<Row>
						<Col md={4}>
							<figure>
								<img alt={"me"}
									 className="hello-me"
									 src={Me} />
							</figure>
						</Col>

						<Col md={8}>
							<p className="introduce-me">
								Hello there!
							</p>

							<p className="introduce-me">
								My name is SeungHwan, G-UL, Um and I am a Toronto based Full Stack Developer.
							</p>

							<p className="introduce-me">
								My passion involves making improvements in both the visual and UI of front layer and efficient data handling of backend. I am interested in all kinds of opportunities that allow me to grow and learn as a developer.
							</p>

							<p className="introduce-me">
								Moreover, I am interested in Music, MMA, Go, and Starcraft 1!
							</p>

							<p className="introduce-me">
								Please feel free to contact me :)
							</p>
						</Col>
					</Row>
				</Container>
			</section>
		);
	}
}


export default AboutMe;
