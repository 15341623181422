// React
import React,
	   {Component} from "react";

// App
import LOGO from "../assets/G-UL-Logo-Big.png";


class Header extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {"currentSection": false};

		// Methods
		this.changeSection = this.changeSection.bind(this);
		this.closeNavbar = this.closeNavbar.bind(this);
		this.openNavbar = this.openNavbar.bind(this);
	}


	changeSection()
	{

	}


	closeNavbar()
	{
		var element1 = document.getElementById("hello-header");
		var element2 = document.getElementById("hello-target-navbar");
		element1.classList.remove("open-hello-target-navbar");
		element2.style.display = "none";
		this.setState({currentSection: false});
	}


	openNavbar()
	{
		if (!this.state.currentSection)
		{
			var element1 = document.getElementById("hello-header");
			var element2 = document.getElementById("hello-target-navbar");
			this.setState({currentSection: true});
			element1.classList.add("open-hello-target-navbar");
			element2.style.display = "block";
		}
		else
		{
			this.closeNavbar();
		}
	}


	render()
	{
		return (
			<nav className="navbar
							navbar-default
							navbar-fixed-top
							hello-navbar"
				 id="hello-header">
				<div className="container">
					<div className="navbar-header
									page-scroll">
						<a href="/"
						   id="main-logo1">
							<img alt={"logo"}
								 height="48"
								 width="48"
								 src={LOGO} />
						</a>
					</div>

					<button className="navbar-toggle"
							data-target=".navbar-collapse"
							data-toggle="collapse"
							onClick={this.openNavbar}
							type="button">
						<span className="sr-only">Toggle navigation</span>
						<span className="icon-bar"></span>
						<span className="icon-bar"></span>
						<span className="icon-bar"></span>
					</button>

					<div className="collapse
									navbar-collapse"
						 id="hello-target-navbar">
						<ul className="nav
									   navbar-nav
									   navbar-right">
							{this.state.currentSection &&
								<li>
									<a href="/"
									   id="main-logo2">
										<img alt={"logo"}
											 height="48"
											 width="48"
											 src={LOGO} />
									</a>
								</li>
							}

							<li>
								<a className="page-scroll"
								   href="#AboutMe"
								   onClick={this.closeNavbar}>
									About
								</a>
							</li>

							<li>
								<a className="page-scroll"
								   href="#Experience"
								   onClick={this.closeNavbar}>
									Experience
								</a>
							</li>

							<li>
								<a className="page-scroll"
								   href="#Resume"
								   onClick={this.closeNavbar}>
									Resume
								</a>
							</li>

							<li>
								<a className="page-scroll"
								   href="#ContactMe"
								   onClick={this.closeNavbar}>
									Contact
								</a>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		);
	}
}


export default Header;
