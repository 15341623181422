// React
import React,
	   {Component} from "react";
import {Col,
		Container,
		Row} from "react-bootstrap";

// App
import BCT from "../assets/BCT.png";
import GoCo from "../assets/GoCo.png";
import NA from "../assets/NA.png";
import Telus from "../assets/Telus.png";
import UOFT from "../assets/UOFT.png";
import {CAREER_DETAILS} from "../constants/CareerDetails.js";
import CareerModal from "./CareerModal.js";


class Career extends Component
{
	constructor()
	{
		super();
		this.state = {"company": "",
					  "descriptions": "",
					  "detailKey": "",
					  "isOpen": false,
					  "period": "",
					  "position": ""};
		this.career = CAREER_DETAILS;

		// Methods
		this.closeModal = this.closeModal.bind(this);
		this.openModal = this.openModal.bind(this);
	}


	closeModal()
	{
		this.setState({"company": "",
					   "descriptions": "",
					   "detailKey": "",
					   "isOpen": false,
					   "period": "",
					   "position": ""});
	}


	openModal(detailKey)
	{
		var descriptions = this.career[detailKey].description.map(
			function(item, index)
			{
				return <li className="description" key={index}>{item}</li>
			}
		);

		this.setState({"company": this.career[detailKey].company,
					   "descriptions": descriptions,
					   "detailKey": this.career[detailKey],
					   "isOpen": true,
					   "period": this.career[detailKey].period,
					   "position": this.career[detailKey].position});
	}


	render()
	{
		return (
			<section id="Experience">
				<Container>
					{
						this.state.isOpen &&
						<CareerModal closeModal={this.closeModal}
									 company={this.state.company}
									 descriptions={this.state.descriptions}
									 detailKey={this.state.detailKey}
									 isOpen={this.state.isOpen}
									 period={this.state.period}
									 position={this.state.position} />
					}

					<Row>
						<Col className="text-center"
							 md={12}>
							<h2 className="experience-header">
								Experience
							</h2>
						</Col>
					</Row>

					<Row>
						<Col className="text-center"
							 md={12}>
							<ul className="timeline">
								<li>
									<div className="timeline-image">
										<a href="https://www.utoronto.ca/"
										   rel="noopener noreferrer"
										   target="_blank">
											<img alt={"UOFT"}
												 className="round-image
															img-responsive"
												 src={UOFT} />
										</a>
									</div>

									<div className="timeline-description													clickable"
										 onClick={() => this.openModal("uoft")}>
										<div className="timeline-heading">
											<h4>University of Toronto, St. George Campus</h4>

											<h5 className="period-text">
												September 2009 - April 2013
											</h5>

											<h5 className="period-text">
												May 2014 - April 2016
											</h5>
										</div>

										<div className="timeline-body">
											<p className="job-title">
												Honours Bachelor of Science in Human Biology and Biology
											</p>
										</div>
									</div>
								</li>

								<li>
									<div className="timeline-image">
										<img alt={"coop"}
											 className="round-image
														img-responsive"
											 src={NA} />
									</div>

									<div className="timeline-description
													clickable"
										 onClick={() => this.openModal("internship")}>
										<div className="timeline-heading">
											<h4>Projektio.io</h4>

											<h5 className="period-text">
												April 2016 - April 2017
											</h5>
										</div>

										<div className="timeline-body">
											<p className="job-title">
												Software Enginner Internship
											</p>
										</div>
									</div>
								</li>

								<li>
									<div className="timeline-image">
										<a href="https://www.broadconnect.ca/"
										   rel="noopener noreferrer"
										   target="_blank">
											<img alt={"BCT"}
												 className="round-image
															img-responsive"
												 src={BCT} />
										</a>
									</div>

									<div className="timeline-description
													clickable"
										 onClick={() => this.openModal("bcc")}>
										<div className="timeline-heading">
											<h4>BroadConnect Canada</h4>

											<h5 className="period-text">
												September 2017 - April 2019
											</h5>
										</div>

										<div className="timeline-body">
											<p className="job-title">
												Full Stack Software Enginner
											</p>
										</div>
									</div>
								</li>

								<li>
									<div className="timeline-image">
										<a href="https://www.goco.ca/"
										   rel="noopener noreferrer"
										   target="_blank">
											<img alt={"GoCo"}
												 className="round-image
															img-responsive"
												 src={GoCo} />
										</a>
									</div>

									<div className="timeline-description
													clickable"
										 onClick={() => this.openModal("goco")}>
										<div className="timeline-heading">
											<h4>GoCo Technology Inc.</h4>

											<h5 className="period-text">
												April 2019 - April 2024
											</h5>
										</div>

										<div className="timeline-body">
											<p className="job-title">
												Developer Analyst I
											</p>
										</div>
									</div>
								</li>

								<li>
									<div className="timeline-image">
										<a href="https://www.telus.ca/"
										   rel="noopener noreferrer"
										   target="_blank">
											<img alt={"Telus"}
												 className="round-image
															img-responsive"
												 src={Telus} />
										</a>
									</div>

									<div className="timeline-description
													clickable"
										 onClick={() => this.openModal("telus")}>
										<div className="timeline-heading">
											<h4>TELUS Communication Inc.</h4>

											<h5 className="period-text">
												April 2024 - Current
											</h5>
										</div>

										<div className="timeline-body">
											<p className="job-title">
												Senior Developer Analyst
											</p>
										</div>
									</div>
								</li>
							</ul>
						</Col>
					</Row>
				</Container>
			</section>
		);
	}
};


export default Career;
