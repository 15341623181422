// BASE URL
export const BASE_URL = "https://g-ul.me"

// CSRF URL
export const CSRF = "/csrf";

// DEV BASE URL
export const DEV_BASE_URL = "http://127.0.0.1:8000";

// CONTACT URL
export const CONTACT_ME_URL = "https://g-ul.me/api/account/contact_me/";

// CSRF
export const CSRF_URL = "/csrf";

// IP
export const IP_ULR = "https://api.ipify.org/?format=json";

// VISTITOR STATS API URL
export const VISITOR_API = "/api/country/ip_count_update/"

