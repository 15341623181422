// React
import React,
	   {Component} from "react";
import {Container,
		Row} from "react-bootstrap";

// Package
import Typed from "typed.js";

// App
import Background from "../assets/Wall_with_Lights.jpg";


class HelloThere extends Component
{
	componentDidMount()
	{
		// https://github.com/mattboldt/typed.js
		const strings = ["Hello World! <br>This is <strong>SeungHwan</strong>!<br>Thank you for visiting my place :)"];
		const options = {backSpeed: 50,
						 strings: strings,
						 // loop: true
						 typeSpeed: 50};

		this.typed = new Typed(this.el,
							   options);
	}


	render()
	{
		return (
			<header className="hello-world"
					style={{backgroundImage: `url(${Background})`}}>
				<Container>
					<div className="hello-padding">
						<Row>
							<span className="hello-world-text"
								  ref={(el) => {this.el = el;}} />
						</Row>
					</div>
				</Container>
			</header>
		);
	}
}


export default HelloThere;
