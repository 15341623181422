// Packages
import React,
	   {Component} from "react";
import cookies from "react-cookies";
import "bootstrap/dist/css/bootstrap.css";

// Apps
import "./App.css";
import {BASE_URL,
		CSRF_URL,
		IP_ULR,
		VISITOR_API} from "./constants/baseUrl.js";
import AboutMe from "./containers/AboutMe.js";
import Career from "./containers/Career.js";
import ContactMe from "./containers/ContactMe.js";
import Footer from "./containers/Footer.js";
import Header from "./containers/Header.js";
import HelloThere from "./containers/HelloThere.js";
import Resume from "./containers/Resume.js";


async function getCsrfToken()
{
	const csrfToken = cookies.load("csrfToken");
	let requestConfig = {"headers": {"Accept": "application/json",
									 "Content-Type": "application/json",
									 "Credentials": "same-origin"},
						 "method": "GET"};
	if (!csrfToken)
	{
		let url = BASE_URL + CSRF_URL;
		const response = await fetch(url,
									 requestConfig);

		const data = await response.json();
		const newCsrfToken = data.csrfToken;
		cookies.save("csrfToken",
					 newCsrfToken);
	}

	visitorStats();
}


async function visitorStats()
{
	fetch(IP_ULR).then(
		response => response.json()
	).then((data) => {
		const csrfToken = cookies.load("csrfToken");
		const token = cookies.load("token");
		let header = {"Accept": "application/json",
					  "Content-Type": "application/json",
					  "Credentials": "same-origin"}
		if (csrfToken) header["X-CSRFTOKEN"] = csrfToken;

		if (token) header["Authorization"] = "Token " + token;

		let requestConfig = {"headers": header,
							 "method": "POST"};
		requestConfig["body"] = JSON.stringify(data);
		let url = BASE_URL + VISITOR_API;

		fetch(
			url,
			requestConfig
		).then(
			response => response.json()
		).then(
			(data) => {

			}
		).catch((data) => {
			console.log(data);
		});
	}).catch(
		(data) => {
			console.log(data);
		}
	);
}


class App extends Component
{
	componentDidMount()
	{
		getCsrfToken();
	}

	render()
	{
		return (
			<div className="App">
				<Header />

				<HelloThere />

				<AboutMe />

				<Career />

				<Resume />

				<ContactMe />

				<Footer />
			</div>
		);
	}
}

export default App;
