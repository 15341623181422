// React
import React,
	   {Component} from "react";
import cookies from "react-cookies";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope,
		faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import {Button,
		Col,
		Container,
		Form,
		Row} from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";

// App
import {CONTACT_ME_URL} from "../constants/baseUrl.js";



class ContactMe extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {alert: null,
					  email: "",
					  flags: {emailFlag: false,
							  messageFlag: false,
							  subjectFlag: false},
					  isOkToSubmit: false,
					  formErrors: {emailErrorMsg: "",
								   messageErrorMsg: "",
								   subjectErrorMsg: ""},
					  message: "",
					  subject: ""};
		this.handleUserInput = this.handleUserInput.bind(this);
		this.submitEmail = this.submitEmail.bind(this);
	}


	close(flag)
	{
		this.setState({alert: null});
		window.location.reload();
	}


	errorClass(error)
	{
		return (error.length === 0 ? "" : "has-error");
	}


	handleUserInput(event)
	{
		const name = event.target.name;
		const value = event.target.value;
		event.preventDefault();
		this.setState({[name]: value},
					  () => {this.validateField(name,
												value)});
	}


	setAlert(flag,
			 msg)
	{
		if (flag)
		{
			this.setState({
				alert: (
					<SweetAlert confirmBtnText="Confirm"
								confirmBtnBsStyle="success"
								onConfirm={() => this.close(flag)}
								title="Success"
								success>
						{msg}
					</SweetAlert>
				)
			});
		}
		else
		{
			this.setState({
				alert: (
					<SweetAlert confirmBtnText="Confirm"
								confirmBtnBsStyle="danger"
								onConfirm={() => this.close(flag)}
								title="Error"
								warning>
						{msg}
					</SweetAlert>
				)
			});
		}
	}


	submitEmail(event)
	{
		event.preventDefault();
		const data = {contents: this.state.message,
					  from: this.state.email,
					  subject: this.state.subject}

		const csrfToken = cookies.load("csrfToken");
		const token = cookies.load("token");
		let url = CONTACT_ME_URL;
		let header = {"Accept": "application/json",
					  "Content-Type": "application/json",
					  "Credentials": "same-origin"}

		if (csrfToken)
		{
			header["X-CSRFTOKEN"] = csrfToken;
		}

		if (token)
		{
			header["Authorization"] = "Token " + token;
		}

		let requestConfig = {"headers": header,
							 "method": "POST"};
		if (Object.keys(data).length)
		{
			requestConfig["body"] = JSON.stringify(data);
		}

		let promise = fetch(
			url,
			requestConfig
		).then(
			response => {

				return response.json().then((json) => {
					if (!response.ok)
					{
						return Promise.reject(json);
					}

					return Object.assign({},
										 json,
										 {"responseStatus": response.status});
				});
			}
		);

		promise.then((response) => {
			this.setAlert(true,
						  response.message);
		}).catch((response) => {
			this.setAlert(false,
						  response.message);
		});
	}


	validateEmail(email)
	{
		if (!email.length)
		{
			return false;
		}

		var regex = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;

		return regex.test(email);
	}


	validateField(fieldName,
				  value)
	{
		let formErrors = this.state.formErrors;
		let flags = this.state.flags;

		switch(fieldName)
		{
			case "email":
				flags.emailFlag = this.validateEmail(value);
				formErrors.emailErrorMsg = flags.emailFlag ? "" : "Email is invalid.";
				break;
			case "message":
				flags.messageFlag = value.length >= 8;
				formErrors.messageErrorMsg = flags.messageFlag ? "" : "Message is too short :(";
				break;
			case "subject":
				flags.subjectFlag = value.length >= 8;
				formErrors.subjectErrorMsg = flags.subjectFlag ? "" : "Subject is too short :(";
				break;
			default:
				break;
		}

		this.setState({flags: flags,
					   formErrors: formErrors},
					  this.validateForm);
	}


	validateForm()
	{
		this.setState({isOkToSubmit: this.state.flags.emailFlag &&
									 this.state.flags.messageFlag &&
									 this.state.flags.subjectFlag});
	}


	render()
	{
		return (
			<section id="ContactMe">
				<Container>
					<Row>
						<Col className="email-me"
							 md={12}>
							<span>
								Get in touch <FontAwesomeIcon icon={faEnvelope} />
							</span>
						</Col>
					</Row>

					<Form>
						<Row>
							<Col className="text-center"
								 md={12}>
								<Form.Group className={`${this.errorClass(this.state.formErrors.emailErrorMsg)}`}
											controlId="formEmail">
									<Form.Control name="email"
												  onChange={(event) => this.handleUserInput(event)}
												  placeholder="Please enter your email."
												  required
												  type="email"
												  value={this.state.email} />

									{
										this.state.formErrors.emailErrorMsg.length > 0 &&
										<div className="contact-me-error-msg">
											{this.state.formErrors.emailErrorMsg}
										</div>
									}
								</Form.Group>
							</Col>
						</Row>

						<Row>
							<Col className="text-center"
								 md={12}>
								<Form.Group className={`${this.errorClass(this.state.formErrors.subjectErrorMsg)}`}
											controlId="formSubject">
									<Form.Control name="subject"
												  onChange={(event) => this.handleUserInput(event)}
												  placeholder="Please enter subject."
												  required
												  type="text"
												  value={this.state.subject} />

									{
										this.state.formErrors.subjectErrorMsg.length > 0 &&
										<div className="contact-me-error-msg">
											{this.state.formErrors.subjectErrorMsg}
										</div>
									}
								</Form.Group>
							</Col>
						</Row>

						<Row>
							<Col className="text-center"
								 md={12}>
								<Form.Group className={`${this.errorClass(this.state.formErrors.messageErrorMsg)}`}
											controlId="formMessage">
									<Form.Control as="textarea"
												  name="message"
												  onChange={(event) => this.handleUserInput(event)}
												  placeholder="Please enter message."
												  required
												  rows="8"
												  style={{resize: "none"}}
												  type="text"
												  value={this.state.message} />

									{
										this.state.formErrors.messageErrorMsg.length > 0 &&
										<div className="contact-me-error-msg">
											{this.state.formErrors.messageErrorMsg}
										</div>
									}
								</Form.Group>
							</Col>
						</Row>

						<Row style={{textAlign: "right"}}>
							<Col md={12}>
								<Button className="email-send-button"
										disabled={!this.state.isOkToSubmit}
										onClick={this.submitEmail}
										variant="dark">
									Send :) <FontAwesomeIcon icon={faPaperPlane} />
								</Button>
							</Col>
						</Row>
					</Form>
				</Container>
				{this.state.alert}
			</section>
		);
	}
}


export default ContactMe;
