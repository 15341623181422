// React
import React,
	   {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {Col,
		Container,
		Row} from "react-bootstrap";

// Projects
import resume from "../assets/Resume.pdf";
import resume01 from "../assets/Resume1.png";
import resume02 from "../assets/Resume2.png";


class Resume extends Component
{
	render()
	{
		return (
			<section id="Resume">
				<Container>
					<Row>
						<Col className="text-center"
							 md={12}>
							<h2 className="resume-header">
								Resume
							</h2>
						</Col>
					</Row>

					<Row>
						<Col className="text-center"
							 md={12}
							 style={{display: "block"}}>
							<img alt="resume1"
								 className="resume-pdf"
								 id="ResumeImage"
								 src={resume01}>
							</img>

							<img alt="resume2"
								 className="resume-pdf"
								 id="ResumeImage"
								 src={resume02}>
							</img>

							<a className="btn
										  btn-default
										  text-center"
							   download="Um, SeungHwan.pdf"
							   href={resume}
							   style={{marginTop: "24px"}}
							   type="button">
								Download <FontAwesomeIcon icon={faDownload} />
							</a>
						</Col>
					</Row>
				</Container>
			</section>
		);
	}
};


export default Resume;
