// React
import React,
	   {Component} from "react";
import {Modal} from "react-bootstrap";



class CareerModal extends Component
{
	constructor(props)
	{
		super(props);
		this.modalRef = React.createRef();
	}


	render()
	{
		return (
			<div className="modal-dialog">
				<Modal backdrop={"static"}
					   bssize={"md"}
					   id="careerModal"
					   keyboard={false}
					   onHide={this.props.closeModal}
					   ref={this.modalRef}
					   show={this.props.isOpen}
					   style={{opacity:1}}>
					<div className="modal-header"
						 style={{padding: "25px"}}>
						<button className="close"
								onClick={this.props.closeModal}
								type="button">
							&times;
						</button>

						<Modal.Title>
							<h2 className="company">{this.props.company}</h2>
							<h3 className="period">{this.props.period}</h3>
							<h4 className="position">{this.props.position}</h4>
						</Modal.Title>
					</div>

					<Modal.Body style={{background: "#f5f5f5"}}>
						<ul>
							{this.props.descriptions}
						</ul>
					</Modal.Body>

					<Modal.Footer style={{textAlign: "center"}}>
						<button className="btn
										   btn-default"
								onClick={this.props.closeModal}>
							Close
						</button>
					</Modal.Footer>
				</Modal>
			</div>

		);
	}
};


export default CareerModal;
