// Career Details
export const CAREER_DETAILS = {
	"uoft": {
		"company": "University of Toronto, St. George Campus",
		"period": "September 2009 - April 2013\nMay 2014 - April 2016",
		"position": "Honours Bachelor of Science in Human Biology and Biology",
		"description": [
			"Majored in Human Biology and Biology",
			"Relevant Courses: Introduction to Database, Data Structures and Analysis, Software Tools and Systems Programming, Software Design, Introduction to the Theory of Computation, Linear Algebra I, Probability with Computer Application, Calculus"
		]
	},
	"internship": {
		"company": "Projektio.io",
		"period": "April 2016 - April 2017",
		"position": "Software Enginner Internship",
		"description": [
			"Contributed to the development of a business article website, implementing features such as automated Twitter thread creation.",
			"Played a key role in writing unit tests and integrating deployment processes for improved efficiency."
		]
	},
	"bcc": {
		"company": "BroadConnect Canada",
		"period": "September 2017 - April 2019",
		"position": "Full Stack Software Enginner",
		"description": [
			"Led the design and implementation of an agent portal, enabling automated provisioning of BroadSoft services and efficient management of phone numbers.",
			"Implemented API logging for tracking important actions, contributing to system transparency and accountability.",
			"Contributed to the development of a web application synchronized with VoIP phones, enabling remote management of phone actions.",
			"Collaborated closely with senior developer and project manager to deliver user-friendly solutions.",
			"Enhanced the UI of a ticketing portal for improved user experience and responsiveness.",
			"Implemented reporting functionalities to track resolution times and optimize support processes."
		]
	},
	"goco": {
		"company": "GoCo Technology Inc.",
		"period": "April 2019 - April 2024",
		"position": "Developer Analyst I",
		"description": [
			"Key contributor in building a comprehensive system for Telus Health reseller clients, integrating BroadSoft services and Northern 911 functionalities.",
			"Implemented permission-based service configuration systems, ensuring secure and tailored access for different user roles.",
			"Developed robust API solutions, facilitating seamless communication between clients and internal systems.",
			"Enhanced functionality with features like API logging and documentation, promoting transparency and ease of use.",
			"Played a vital role in developing a full-stack web application for hosted business phone services, focusing on service configuration and management.",
			"Implemented innovative solutions such as bulk service configuration and custom directory lists, enhancing user experience and efficiency."
		]
	},
	"telus": {
		"company": "TELUS Communication Inc.",
		"period": "April 2024 - Current",
		"position": "Senior Developer Analyst",
		"description": [
			"Demonstrated leadership in designing and implementing a web application for SIP trunking service streamlining provisioning processes.",
			"Successfully managed access and administration across multiple servers, enhancing operational efficiency."
		]
	}
};
